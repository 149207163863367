/// 要素の絶対配置（`position: absolute`） プロパティを簡潔に設定する方法を提供する
///
/// @param {list} $position [null null null null]
///   要素の位置。4つまで指定可。`top`, `right`, `bottom`, `left` の文字列もしくは 単位付きの数値を受け入れる。
///   数値指定の場合、top, right, bottom, left の順で値がセットされる
///
/// @require {function} _in-array()
///
/// @example scss
///   .element01 { @include _absolute(top left); }
///
///   // CSS Output
///   .element01 {
///     position: absolute;
///     top: 0;
///     left: left;
///   }
///
///   .element02 { @include _absolute(20px null 40px); }
///
///   // CSS Output
///   .element02 {
///     position: absolute;
///     top: 20px;
///     bottom: 40px;
///   }

@mixin _absolute(
  $position: null null null null
) {
  @if length($position) > 4 { @error "The number of values must be less than or equal to 4."; }
  $count: 1;
  $properties: top, right, bottom, left;

  position: absolute;

  @each $i in $position {
    @if _in-array($i, $properties) == true { #{$i}: 0; }
    @else { #{nth($properties, $count)}: $i; }
    $count: $count + 1;
  }
}
