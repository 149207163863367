/// ブラウザのデフォルトスタイルをリセットする
///
/// @param {string} $element
///   リセットしたい HTML タグ
///
/// @example scss
///   ul {
///     @include _unstyled(ul);
///   }
///
///   // CSS Output
///   ul {
///     margin: 0;
///     padding-left: 0;
///     list-style-type: none;
///   }

@mixin _unstyled(
  $element
) {
  @if      $element == 'body'       { margin: 0; }
  @else if $element == 'p'          { margin: 0; }
  @else if $element == 'h'          { margin: 0;
                                      font-size: 100%;
                                      font-weight: normal;
                                    }
  @else if $element == 'ol'
        or $element == 'ul'         { margin: 0;
                                      padding-left: 0;
                                      list-style-type: none;
                                    }
  @else if $element == 'dl'         { margin: 0; }
  @else if $element == 'dd'         { margin-left: 0; }
  @else if $element == 'hr'         { margin: 0;
                                      border: none;
                                    }
  @else if $element == 'table'      { border-collapse: collapse;
                                      border-spacing: 0;
                                      border-color: transparent;
                                    }
  @else if $element == 'th'         { font-weight: normal;
                                      text-align: left;
                                      padding: 0;
                                    }
  @else if $element == 'td'         { padding: 0; }
  @else if $element == 'button'     { appearance: none;
                                      padding: 0;
                                      border: none;
                                      background: none;
                                      color: inherit;
                                      //font-size: 100%;
                                      //font-family: inherit;
                                    }
  @else if $element == 'textarea'   { appearance: none;
                                      text-rendering: auto;
                                      background-color: transparent;
                                      resize: vertical; //レイアウトが崩れるのを防ぐため縦方向のみ許可
                                      padding: 0;
                                      border-radius: 0; //iOS用、要検証
                                    }
}
