/// フォントスタックを出力する変数
///
/// @type {list}
///
/// @example scss
///

// `font-family` 指定についての留意点
//
// - N 付きフォント（JIS2004 字形対応文字：略字風だった 168 文字について旧字風に変更されたもの）がある場合はそちらを使う
//   Windows Vista 以降の標準フォントや Mac OS X10.5 以降の「ヒラギノ角ゴ ProN」搭載など、N 付きが主流になっているので。
//
// - 和文フォントの日本語表記とアルファベット表記の併記について、よほど古いブラウザが対象に入っていなければ日本語表記は不必要
//   以前は Safari が日本語表記を、Firefox がアルファベット表記を無視していたが、現在はどちらも対応されているので。
//
// - 游ゴシック・游明朝について
//   Windows と Mac で名称自体が違うので注意する（Win: Yu Gothic / Yu Mincho、Mac: YuGothic / YuMincho）
//   初期値の Regular だと Chrome では線が細いので、ルートに `font-weight:500` を指定し、Medium があたるようにする
//   ※ `font-family: "Yu Gothic Medium"` で Medium があたるようにした場合、
//   `font-weight: bolder` を指定すると表示が汚くなるため避ける
//
// - San Francisco について
//   OS X 10.11 以降及び iOS 9 以降のシステムフォント。本文用はスペーシングがゆったりとしていて、Helvetica よりも視認性が高い。
//   複数の書体を併せ持つため、指定の際にはそのまま指定するのではなく、Blink 系のブラウザ用に BlinkMacSystemFont,
//   それ以外のブラウザ用に -apple-system を指定する。

// ヒラギノ角ゴ
$font-stack-hiragino-kakugo: (
  -apple-system, BlinkMacSystemFont, // = San Francisco
  "Helvetica Neue",
  "Segoe UI", // Arial よりも視認性が高いため指定
  "Hiragino Kaku Gothic ProN",
  Meiryo,
  sans-serif
) !default;

// ヒラギノ明朝
$font-stack-hiragino-mincho: (
  Georgia,
  "Hiragino Mincho ProN",
  "HG明朝B",
  serif,
) !default;

// 游ゴシック（Medium）
$font-stack-yu-gothic-medium: (
  -apple-system, BlinkMacSystemFont,
  "Helvetica Neue",
  "Segoe UI",
  "YuGothic Medium",
  "Yu Gothic Medium",
  "Hiragino Kaku Gothic ProN",
  Meiryo,
  sans-serif
) !default;

// 游ゴシック（Regular）
$font-stack-yu-gothic: (
  -apple-system, BlinkMacSystemFont,
  "Helvetica Neue",
  "Segoe UI",
  "YuGothic",
  "Yu Gothic",
  "Hiragino Kaku Gothic ProN",
  Meiryo,
  sans-serif
) !default;

// 游明朝
$font-stack-yu-mincho: (
  Georgia,
  YuMincho,
  "Yu Mincho",
  "HG明朝B",
  serif
) !default;

// Quicksand
// 使用する際の font-face 宣言例
// @include _font-face('Quicksand', '../font/Quicksand-Medium',normal,null,ttf);
// @include _font-face('Quicksand', '../font/Quicksand-Bold',bolder,null,ttf);
$font-stack-quicksand :(
  Quicksand,
  sans-serif
) !default;
