/// セクションごとの装飾用余白
$groove-size: 5px;

/// 基本タイポグラフィ設定
$base-font-size-px: 10px; // vertical rhythum 用
$base-line-height-px: 26px; // vertical rhythum 用

// 色設定
$base-font-color: #000;
$primary-color: #01509d;
$secondary-color: #ce1717;
$tertiary-color: #241e1c;
$background-color: #f2f2f2;
