html {
  font-size: $base-font-size-px;
}
body {
  color: $base-font-color;
  font-family: $font-stack-yu-gothic-medium;
}
a {
  color: $base-font-color;
  text-decoration: none;
}
img,
video {
  max-width: 100%;
  height: auto;
}
h1,
h2 { @include _unstyled(h); }
p { @include _unstyled(p); }
