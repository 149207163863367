/// メニュー
///
/// 右寄せ、中央寄せ、左寄せを指定するには Modifier（`.menu--{left, center, right}`） を使用する
/// Modifier を使用しない場合は、各アイテムの幅が均等に配置される
///
/// @example html
///   <ul class="menu menu--center">
///     <li class="menu__item"><a href="#">メニューアイテム</a></li>
///     <li class="menu__item"><a href="#">メニューアイテム</a></li>
///     <li class="menu__item"><a href="#">メニューアイテム</a></li>
///     <li class="menu__item"><a href="#">メニューアイテム</a></li>
///   </ul>

.menu {
  @include _unstyled(ul);
  @include _row(middle,null,nowrap);

  &--left   { justify-content: flex-start; }
  &--center { justify-content: center;     }
  &--right  { justify-content: flex-end;   }
}

.menu__item {
  position: relative;
  @include _row-col(equal);

  .menu--left > &,
  .menu--center > &,
  .menu--right > & { @include _row-col(auto); }
}

.menu__item > a {
  display: block;
  text-align: center;
}
