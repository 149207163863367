/// ホバー時のボーダーアニメーションを生成
///
/// `transform-origin` を指定しているので IE10 は非対応
///
/// @param {string} $type
///   ホバーエフェクトの種類
///   `underline-from-left`, `underline-from-center`, `underline-from-right`
///   `overline-from-left`, `overline-from-center`, `overline-from-right`
///   `underline-left-to-right`, `underline-right-to-left`,
///   `overline-left-to-right`, `overline-right-to-left`,
///   `underline-fade`, `underline-fade-up`, `underline-fade-down`,
///   `overline-fade`, `overline-fade-up`, `overline-fade-down` を受け入れる
///
/// @param {number (in px)} $weight
///   ボーダーの太さ
///
/// @param {color} $color [#000]
///   ボーダーの色
///
/// @param {number (in s)} $speed [$hover-animation-speed]
///   アニメーションスピード
///
/// @param {string} $pseudo ['before']
///
/// @require {valiable} $hover-animation-speed
/// @require {valiable} $hover-fade-slip-position
///
/// @example scss
///   .element { @include _hover-border(border-fade,2px,#fff); }
///
///   // CSS Output
///   .element a { position: relative; }
///   .menu__item a::before {
///     content: '';
///     z-index: -1;
///     position: absolute;
///     left: 0;
///     width: 100%;
///     top: 0;
///     height: 100%;
///     border: 2px solid #fff;
///     opacity: 0;
///     transition: opacity 0.3s ease-out;
///   }
///   .menu__item a:hover::before,
///   .menu__item a:focus::before { opacity: 1; }

@mixin _hover-border(
  $type,
  $weight,
  $color: #000,
  $speed: $hover-animation-speed,
  $slip: $hover-fade-slip-position,
  $pseudo: 'before'
) {
  position: relative;

  &::#{$pseudo} {
    content: '';
    z-index: -1;  // 要素内のコンテンツがボーダーで隠れるのを防ぐ
    position: absolute;
    left: 0;
    width: 100%;

    @if $type == 'underline-from-left'
     or $type == 'underline-from-center'
     or $type == 'underline-from-right'
     or $type == 'overline-from-left'
     or $type == 'overline-from-center'
     or $type == 'overline-from-right'
    {
      height: $weight;
      background: $color;
      transform: scaleX(0);
      transition: transform $speed ease-out;
    }
    @if $type == 'underline-left-to-right'
     or $type == 'underline-right-to-left'
     or $type == 'overline-left-to-right'
     or $type == 'overline-right-to-left'
    {
      height: $weight;
      background: $color;
      transform: scale(0, 1);
      transition: transform $speed ease-out;
    }
    @if $type == 'underline-fade'
     or $type == 'underline-fade-up'
     or $type == 'underline-fade-down'
     or $type == 'overline-fade'
     or $type == 'overline-fade-up'
     or $type == 'overline-fade-down'
    {
      height: $weight;
      background: $color;
      opacity: 0;
      transition: all $speed ease-out;
    }
    @if      $type == 'underline-from-left'   { bottom: 0; transform-origin: left;   }
    @else if $type == 'underline-from-center' { bottom: 0; transform-origin: center; }
    @else if $type == 'underline-from-right'  { bottom: 0; transform-origin: right;  }
    @else if $type == 'overline-from-left'    { top: 0; transform-origin: left;      }
    @else if $type == 'overline-from-center'  { top: 0; transform-origin: center;    }
    @else if $type == 'overline-from-right'   { top: 0; transform-origin: right;     }
    @else if $type == 'underline-left-to-right'  { bottom: 0; transform-origin: right top;  }
    @else if $type == 'underline-right-to-left'  { bottom: 0; transform-origin: left top;   }
    @else if $type == 'overline-left-to-right'   { top: 0; transform-origin: right top;  }
    @else if $type == 'overline-right-to-left'   { top: 0; transform-origin: left top;   }
    @else if $type == 'underline-fade'      { bottom: 0; }
    @else if $type == 'overline-fade'       { top: 0;    }
    @else if $type == 'underline-fade-up'   { bottom: 0; transform: translateY($slip);  }
    @else if $type == 'underline-fade-down' { bottom: 0; transform: translateY(-$slip); }
    @else if $type == 'overline-fade-up'    { top: 0; transform: translateY($slip);     }
    @else if $type == 'overline-fade-down'  { top: 0; transform: translateY(-$slip);    }
    @if $type == 'border-fade' {
      top: 0;
      height: 100%;
      border: $weight solid $color;
      opacity: 0;
      transition: opacity $speed ease-out;
    }
  }

  &:hover::before,
  &:focus::before {
    @if $type == 'underline-from-left'
     or $type == 'underline-from-center'
     or $type == 'underline-from-right'
     or $type == 'overline-from-left'
     or $type == 'overline-from-center'
     or $type == 'overline-from-right' {
      transform: scaleX(1);
    }
    @if $type == 'underline-left-to-right'
     or $type == 'overline-left-to-right' {
      transform-origin: left top; transform: scale(1, 1);
    }
    @if $type == 'underline-right-to-left'
     or $type == 'overline-right-to-left' {
      transform-origin: right top;
      transform: scale(1, 1);
    }
    @if $type == 'underline-fade'
     or $type == 'overline-fade' {
      opacity: 1;
    }
    @if $type == 'underline-fade-up'
     or $type == 'underline-fade-down'
     or $type == 'overline-fade-up'
     or $type == 'overline-fade-down' {
      opacity: 1;
      transform: translateY(0);
    }
    @if $type == 'border-fade' {
      opacity: 1;
    }
  }
}
