/// ブラウザ幅によるスタイル分岐のためのメディアクエリ記述を簡略化
///
/// @param {string | number (with unit)} $breakpoint [md]
///    ブレイクポイント。数値もしくは `$breakpoints` に設定したキーを受け入れる。
///
/// @param {string} $range [up]
///    メディアクエリの適用範囲。`up`（`$breakpoint` 以上）, `down`（`$breakpoint` 以下）を受け入れる。
///
/// @require {variable} $breakpoints
///
/// @example scss
///   .element {
///     @include _mq(sm) { font-size: 12px; }
///     @include _mq(sm, down) { font-size: 12px; }
///     @include _mq(500px) { font-size: 12px; }
///   }
///
///   // CSS Output
///   @media screen and (min-width: 576px) {
///     .element { font-size: 12px; }
///   }
///   @media screen and (max-width: 575px) {
///     .element { font-size: 12px; }
///   }
///  @media screen and (min-width: 500px) {
///    .element { font-size: 12px; }
///  }

@mixin _mq(
  $breakpoint: 'md',
  $range: 'up'
) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint: map-get($breakpoints, $breakpoint);
    @if $range == down { $breakpoint: $breakpoint - 1px }
  }
  @if $range == 'up' {
    @media screen and (min-width: $breakpoint) { @content; }
  } @else if $range == 'down' {
    @media screen and (max-width: $breakpoint) { @content; }
  }
}
