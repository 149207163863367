$drawer-type: fade !default;
$drawer-transition-speed: .3s;

.drawer {
  z-index: 1000;
  overflow: auto;
  position: fixed;
  visibility: hidden;

  @if $drawer-type == 'top' {
    top: 0;
    left: 0;
    width: 100%;
    transform: translate(0, -100%);
    transition: all $drawer-transition-speed ease;
  }
  @else if $drawer-type == 'left' {
    top: 0;
    left: 0;
    height: 100vh;
    transform: translate(-100%, 0);
    transition: all $drawer-transition-speed ease;
  }
  @else if $drawer-type == 'right' {
    top: 0;
    right: 0;
    height: 100vh;
    transform: translate(100%, 0);
    transition: all $drawer-transition-speed ease;
  }
  @else if $drawer-type == 'bottom' {
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translate(0, 100%);
    transition: all $drawer-transition-speed ease;
  }
  @else if $drawer-type == 'fade' {
    width: 100%;
    height: 100vh;
    opacity: 0;
    transition: all $drawer-transition-speed ease;
  }


  &.is-visible {
    @if $drawer-type == 'top'
     or $drawer-type == 'right'
     or $drawer-type == 'bottom'
     or $drawer-type == 'left' {
      visibility: visible;
      transform: translate(0, 0);
    }
    @else if $drawer-type == 'fade' {
      opacity: 1;
      visibility: visible;
    }
  }
}


















.drawer {
  background: #eee;
}

