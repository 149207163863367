$burger-bar-width: 26px !default;
$burger-bar-weight: 3px !default;
$burger-bar-space: 6px !default;
$burger-bar-color: $primary-color !default;
$burger-transition-speed: .15s !default;

.burger-btn {
  position: relative;
  display: inline-block;
  width: $burger-bar-width;
  height: ($burger-bar-weight * 3) + ($burger-bar-space * 2);
  line-height: 0;

  &__bar {
    &,
    &::before,
    &::after {
      position: absolute;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: $burger-bar-weight;
      background-color: $burger-bar-color;
      transition: transform $burger-transition-speed, top $burger-transition-speed $burger-transition-speed;
    }
    &,
    &::after  { top: $burger-bar-weight + $burger-bar-space;    }
    &::before { top: -($burger-bar-weight + $burger-bar-space); }

    @at-root .is-opened & {
      & {
        transform: rotate(45deg) !important;
        transition: transform $burger-transition-speed $burger-transition-speed, top $burger-transition-speed !important;
      }
      &::before,
      &::after {
        top: 0 !important;
        transform: rotate(-90deg) !important;
        transition: transform $burger-transition-speed $burger-transition-speed, top $burger-transition-speed !important;
      }
    }
  }
}
