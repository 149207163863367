@charset "utf-8";

@import 'setting';
@import 'mixin/mixin';

@import 'base/font';
@import 'base/flat-css';
@import 'base/element-styled';
@import 'component/article-list';
@import 'component/btn';
@import 'component/burger-btn';
@import 'component/drawer';
@import 'component/header';
@import 'component/hero';
@import 'component/hidden';
@import 'component/menu';

@import 'common';

.hero {
  padding: $groove-size;
  height: 100vh;
  min-height: 619px;

  &__media { background-color: $tertiary-color; }
  &__content {
    @include _row(center middle,column);
    padding: _vr(2) _vr(2) 0;
    font-feature-settings: "palt";
  }
  &__txt {
    margin-top: _vr(1);
    color: #fff;
    @include _vr-font(36,2);
    font-family: $font-stack-yu-mincho;
  }
  &__action {
    margin-top: _vr(2);
    @include _vr-font(18);
  }
}

.intro {
  padding: _vr(2) _vr(1);

  &__inner {
    @include _row(middle,column);
    @include _mq(sm){ flex-direction: row; }
  }
  &__img-box {
    @include _row-col();
    padding: _vr(1) 0;
    text-align: center;
    img { max-width: 75%; }
  }
  &__txt-box { @include _row-col(); }
  &__heading {
    margin-bottom: _vr(1.5);
    font-weight: bolder;
    font-family: $font-stack-yu-mincho;
    @include  _vr-font(24);
  }
  &__txt {
    margin-bottom: _vr(1.5);
    text-align: justify;
    @include _vr-font(16);
  }
}

.recommend {
  margin: $groove-size;
  @include _row(null,column);
  @include _mq(sm){ flex-direction: row; }

  &__item {
    @include _row-col();
  }
  &__item-inner {
    position: relative;
    display: block;
    height: _vr(10);
    overflow: hidden;
    background-color: $tertiary-color;
    @include _mq(sm){ height: _vr(21); }

    &:hover {
      .recommend__media { transform: scale(1.1); }
    }
  }
  &__media {
    width: 100%;
    height: 100%;
    background: no-repeat center;
    background-size: cover;
    transition: transform .3s;
  }
  &__content {
    @include _absolute(0 null null 0);
    @include _row(center middle);
    width: 100%;
    height: 100%;
  }
  &__heading {
    width: _vr(5);
    padding: _vr(1.25) 0;
    border: 1px solid #fff;
    text-align: center;
    color: #fff;
    font-weight: bolder;
    font-family: $font-stack-yu-gothic;
    @include _vr-font(16);
  }
  &__heading-en {
    display: block;
    font-weight: normal;
    font-family: $font-stack-quicksand;
    @include _vr-font(13);
  }
}

.news {
  margin: $groove-size;
  padding: _vr(3) _vr(1);
  background: $background-color;
  @include _vr-font(15);
}
.article-list {
  &__item-inner {
    margin-bottom: 6px;
    padding: _vr(.5) _vr(1.25);
    background: #fff;
  }
  &__date {
    min-width: _vr(5);
    font-family: $font-stack-quicksand;
  }
  &__content {
    text-align: justify;
    .article-list__item-inner:hover > & { text-decoration: underline; }
  }
}
