/// 各スクリーンサイズに応じて要素を非表示にする
///
/// 要素を非表示にするには、`.hidden-{sm, md, lg, xl}-{up, down}` を使用する
///
/// @example html
///   <div class="hidden-md-down"> md に指定した幅以下で非表示</div>
///   <div class="hidden-md-up"> md に指定した幅以上で非表示</div>

@each $bp in map-keys($breakpoints) {
  .hidden-#{$bp}-up {
    @include _mq( $bp ) { display: none !important; }
  }

  .hidden-#{$bp}-down {
    @include _mq( $bp, down ) { display: none !important; }
  }
}
