// スクロールした時のヘッダー背景色・背景透明度・アニメーション速度
// ヘッダー固定の場合に設定する
$header-scrolled-bg: #fff !default;
$header-scrolled-opacity: .84 !default;
$header-scrolled-duration: .5s !default;

/// ヘッダー
///
/// ウィンドウ上部固定の場合は、Modifier（`.header--fixed`）を使用する
///
/// @example html
///   <header class="header header--fixed">
///     <div class="header__logo">logo</div>
///     <div class="header__menu">menu</div>
///   </header>

.header {
  &--fixed {
    z-index: 100;
    @include _fixed(0 0 null 0);
    transition: all $header-scrolled-duration;

    &.scrolled {
      background: rgba($header-scrolled-bg, $header-scrolled-opacity);
    }
  }
}
