/// 小数を指定した桁数で丸める
///
/// @param {number} $number
///   丸めたい数値。
///
/// @param {number} $digits [0]
///   丸める桁数。マイナスの値は不可。
///
/// @param {string} $mode [round]
///   丸め方。
///   `round`（四捨五入）, `ceil`（切り上げ）, `floor`（切り下げ）を受け入れる。
///
/// @return {number}
///
/// @example
///   @debug _to-fixed(123.456);           // => 123
///   @debug _to-fixed(123.456, 0, ceil);  // => 124
///   @debug _to-fixed(123.456, 2, floor); // => 123.45

@function _to-fixed(
  $number,
  $digits: 0,
  $mode: round
) {
  $n: 1;
  @if $digits != 0 {
    @for $i from 1 through $digits { $n: $n * 10; }
  }

  @if      $mode == ceil  { @return ceil($number * $n) / $n;  }
  @else if $mode == floor { @return floor($number * $n) / $n; }
  @else    { @return round($number * $n) / $n; }
}
