/// 横並びレイアウト用のコンテナを生成する
///
/// @param {list} $position [null]
///   カラムの配置。
///   `left`（左寄せ）, `center`（中央寄せ）, `right`（右寄せ）, `between`（両端揃え）, `around`（均等揃え）,
///   `top`（上揃え）, `middle`（中央揃え）, `bottom`（下揃え）, `stretch`（カラムの高さを揃える）を受け入れる。
///   水平方向・水平方向の指定がない場合は、それぞれ `left`, `stretch` と同じ挙動になる。
///
/// @param {string} $reverse [row]
///   カラムの並ぶ方向。`row`（書字方向）、`reverse`（書字とは逆方向）、`column`（縦方向）を受け入れる。
///
/// @param {string} $wrap [wrap]
///   カラムの折り返し。`wrap`（折り返す）、`nowrap`（折り返さない）を受け入れる。
///
/// @example scss
///   .element { @include _row(right middle,reverse); }
///
///   // CSS Output
///   .element {
///     display: flex;
///     flex-wrap: wrap;
///     justify-content: flex-end;
///     align-items: center;
///   }

@mixin _row(
  $position: null,
  $reverse: row,
  $wrap: wrap
) {
  display: flex;
  flex-wrap: $wrap;
  flex-direction: $reverse;

  $row-align-x:  ('left': flex-start, 'center': center, 'right': flex-end, 'between': space-between, 'around': space-around);
  $row-align-y:  ('top': flex-start, 'middle': center, 'bottom': flex-end, 'stretch': stretch);

  @each $i in $position {
    @if map-has-key($row-align-x, $i) { justify-content: map-get($row-align-x, $i); }
    @if map-has-key($row-align-y, $i) { align-items: map-get($row-align-y, $i); }
  }
}
