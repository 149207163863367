// 切り替えるヒーローイメージの数
$hero-item-count: 4 !default;

// 表示時間
// 使用画像が複数枚の場合は設定する
$hero-display-time: 8 !default;

// 切り替え時間
// 使用画像が複数枚の場合は設定する
$hero-transition-time: 3 !default;

/// ヒーローイメージ
///
/// - `.hero` の `height` は必ず指定すること。
/// - 使用画像は `.hero__item` の背景として設定する
///
/// @require {valiable} $hero-item-count
/// @require {valiable} $hero-display-time
/// @require {valiable} $hero-transition-time
///
/// @example html
///   <div class="hero">
///     <div class="hero__media hero__media--scale-up">
///       <div class="hero__item" style="background-image:url('img/hero/hero-01.jpg')"></div>
///       <div class="hero__item" style="background-image:url('img/hero/hero-02.jpg')"></div>
///       <div class="hero__item" style="background-image:url('img/hero/hero-03.jpg')"></div>
///       <div class="hero__item" style="background-image:url('img/hero/hero-04.jpg')"></div>
///     </div>
///     <div class="hero__content">…</div>
///   </div>

// 各値の計算
$hero-duration-time: $hero-item-count * $hero-display-time;
$hero-fadein-timing: percentage($hero-transition-time / $hero-duration-time);
$hero-fadeout-timing: percentage($hero-display-time / $hero-duration-time);
$hero-end-timing: percentage(($hero-display-time + $hero-transition-time) / $hero-duration-time);

.hero {
  position: relative;
  display: block;
  overflow: hidden;
}

.hero__media {
  z-index: 0;
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;

  &--scale-up > .hero__item { animation-name: hero-scale-up; }
  &--scale-down > .hero__item { animation-name: hero-scale-down; }
}

.hero__item {
  @include _absolute(0 null null 0);
  width: 100%;
  height: 100%;
  background: no-repeat center;
  background-size: cover;

  @if $hero-item-count > 1 {
    animation-iteration-count: infinite;
    animation-duration: #{$hero-duration-time}s;
    opacity: 0;

    @for $i from 1 through $hero-item-count {
      &:nth-child(#{$i}) {
        @if $i == 1 { opacity: 1; }
        animation-delay: #{($i * $hero-display-time - ($hero-display-time + $hero-transition-time))}s;
      }
    }
  }
}

.hero__content {
  z-index: 1;
  @include _absolute(0 null null 0);
  display: block;
  width: 100%;
  height: 100%;
}

// アニメーション設定
@keyframes hero-scale-up {
  0% { opacity: 0; transform: scale(1); }
  #{$hero-fadein-timing} { opacity: 1; }
  #{$hero-fadeout-timing} { opacity: 1; }
  #{$hero-end-timing} { opacity: 0; }
  100% { opacity: 0; transform: scale(1.15); }
}
@keyframes hero-scale-down {
  0% { opacity: 0; transform: scale(1.15); }
  #{$hero-fadein-timing} { opacity: 1; }
  #{$hero-fadeout-timing} { opacity: 1; }
  #{$hero-end-timing} { opacity: 0; }
  100% { opacity: 0; transform: scale(1); }
}
