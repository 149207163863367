/// ボタン
///
/// @example html
///   <a href="#" class="btn" role="button">btn</a>

.btn {
  @include _unstyled(button);
  display: inline-block;
  text-decoration: none;
  text-align: center;
  &:not(:disabled){ cursor: pointer; }
}
