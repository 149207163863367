.st0 { fill: #01509D; }
.st1 { fill: #fff; }
.logo--reverse { fill: #fff; }

.box {
  margin: _vr(1.5) 0;
  padding: _vr(1);
  background: $background-color;
  &:last-child { margin-bottom: 0; }
}

.btn {
  padding: _vr(.375)-3px _vr(1)-3px;
  color: #fff;
  font-weight: bolder;
  font-family: $font-stack-yu-gothic;
  border: 3px solid $tertiary-color;
  background-color: $tertiary-color;
  transition: all .2s;
  min-width: _vr(5);

  &:hover {
    background: #fff;
    color: $tertiary-color;
  }

  &--secondary {
    border-color: $secondary-color;
    background: $secondary-color;
    &:hover { color: $secondary-color; }
  }
}

.container {
  max-width: 1092px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  height: _vr(3);
  font-weight: bolder;
  font-family: $font-stack-yu-gothic;
  &.scrolled { height: _vr(2); }

  &__logo {
    @include _absolute(0 null null 0);
    padding: _vr(1) _vr(.5);
    background: #fff;
    transition: all .3s;

    .header.scrolled & {
      background: none;
      padding: _vr(.5);
    }
  }
  &__logo-link {
    @include _mq(md){
      transition: opacity .15s;
      &:hover { opacity: .6; }
    }
  }
  &__menu {
    padding: _vr(.75) 0;
    transition: all .5s;
    .header.scrolled & { padding: _vr(.25) 0; }

    @at-root .menu__item > a {
      margin: 0 _vr(.5);
      padding: _vr(.25);
      color: #fff;
      transition: all .3s;
      @include _vr-font(15);
      @include _hover-border(underline-fade-up,2px,#fff);

      .header.scrolled & {
        color: $base-font-color;
        &::before { background-color: $base-font-color; }
      }
    }
  }
  &__contact { @include _absolute(0 _vr(1)); }
  &__contact-link {
    display: block;
    padding: _vr(.5) _vr(1);
    background: $tertiary-color;
    border-radius: 0 0 4px 4px;
    text-align: center;
    color: #fff;
    @include _vr-font(13);

    @include _mq(md){
      transition: all .15s;
      &:hover { background-color: #3d3230; }
    }
  }
  &__drawer-btn {
    @include _absolute(_vr(.5) _vr(.5));
    transition: all 0.5s;
    .header.scrolled & { transform: translateY(_vr(-.5)); }
  }
}
.drawer-btn-box {
  z-index: 1001;
  @include _fixed(top right);
  padding: _vr(.5);
}
.drawer {
  @include _row(null,column,nowrap);
  padding: _vr(1.5) 0;
}
.drawer-menu {
  $root: #{&};
  margin: _vr(.5) 0;
  padding-left: 0;
  list-style-type: none;

  &__item {
    @include _vr-font(18);
    padding: _vr(.5) 0;
    text-align: center;
  }
  a {
    display: inline-block;
    border-bottom: 1px solid $base-font-color;
    font-weight: bolder;
    font-family: $font-stack-yu-gothic;
  }

  &--small {
    #{$root}__item {
      @include _vr-font(13);
      padding: _vr(.25) 0;
    }
  }
}

.footer {
  @include _row(middle,column);
  margin: $groove-size;
  padding: _vr(1) _vr(1);
  background-color: $tertiary-color;
  color: #d3d2d2;
  @include _vr-font(12,.75);

  @include _mq(md){
    flex-direction: row;
    justify-content: space-between;
  }

  &__company-info {
    @include _row(middle);
    padding: _vr(.5) 0;
  }
  &__address {
    margin-left: _vr(.75);
    padding-left: _vr(.75);
    border-left: 1px solid #767371;
  }
  &__site-info {
    text-align: right;
  }
  &__nav {
    @include _unstyled(ul);
    @include _row(right);
  }
  &__nav-item {
    position: relative;
    margin-left: 1.5em;
    padding-left: 1em;

    &::before {
      content: '';
      @include _arrow(right,5px,1px,#fff);
      @include _absolute(calc(50% - 3px) null null 0);
    }
    > a {
      color: #d3d2d2;
      &:hover { text-decoration: underline; }
    }
  }
  &__copyright {
    color: #767371;
    font-family: $font-stack-quicksand;
  }
}
