/// 横並びレイアウト用のカラムを生成する
///
/// @param {number | string | null} $width [null]
///   カラムの横幅。数値指定の他, `auto`（内部コンテンツ幅）を受け入れる。
///   数値指定の場合、単位無しはパーセンテージに変換された値、単位付きはそのままの値がセットされる
///   未指定の場合、コンテナと内部コンテンツ幅に応じて自動計算される
///
/// @example scss
///   .element01 { @include _row-col(1/3); }
///   .element02 { @include _row-col(auto); }
///
///   // CSS Output
///   .element01 {
///     flex: 0 0 33.33333%;
///     max-width: 33.33333%;
///   }
///   .element02 {
///     flex: 0 1 auto;
///   }

@mixin _row-col(
  $width: null
) {
  @if type-of($width) == 'number' {
    @if unitless($width) { $width: percentage($width); }
    flex: 0 0 $width;
    max-width: $width; // IE で flex-basis に box-sizing が効かないバグへの対策
  }
  @else if $width == 'auto'  { flex: 0 1 auto; }
  @else { flex: 1 1 0%; } // 1
}

// 1. 2018.07.12 修正
//    - 未指定の場合は `flex-grow: 1;` としていたが、IE10-11 において
//      要素の幅が flex コンテナからはみ出して表示されるという問題が起こったので修正
//    - 問題が起きやすい状況：2 カラムレイアウトで片方を固定幅、もう片方を可変にする場合
//    - 原因：flex アイテムの最小幅算出は（`min-width` で明示的に指定していない場合）、
//      アイテム内容の最小幅または `width` プロパティで明示的に示された幅のどちらか小さいものになる。
//      この時、算出には `word-wrap: break-word;` を考慮できないため、内容に長い英単語があると
//      そのテキストの幅に合わせて最小幅がセットされてしまうのが問題と思われる
//      IE11 では `flex-grow: 1;` に加えて `min-width: 0;` を指定すれば解決したが、
//      IE10 では解決できなかったため、最終的に `flex: 1 1 0%;` とした。

