/// ベースとなる行の高さを基準に、任意のフォントサイズから `line-height` の値を算出し、
/// `font-size`（`rem`） と `line-height` プロパティをセットする
/// 主に Vertical Rhythm を実現するために用いる Mixin
///
/// @param {number (in px)} $font-size
///   フォントサイズ。単位を省略した場合も px で計算される。
///   Chrome で表示される最小フォントサイズが 10px のため、9px 以下を指定すると
///   正常な数値を出すことができない点に注意。
///
/// @param {number} $lines [1]
///   行数。
///
/// @param {number (in px)} $base-line-height [$base-line-height-px]
///   ベースとなる行の高さ。
///
/// @example scss
///   .element {
///     @include _vr-font(14, 1, 26px);
///   }
///
///   // CSS Output
///   .element {
///     font-size: 1.4rem;
///     line-height: 1.8571429;
///   }

@mixin _vr-font(
  $font-size,
  $lines: 1,
  $base-line-height: $base-line-height-px
) {
  @if unit($font-size) != 'px' and not unitless($font-size) {
    @error "The font size should be specified in px.";
  }
  font-size: _convert-length($font-size * 1px, rem);
  line-height: _vr-line-height($font-size, $lines, $base-line-height);
}



