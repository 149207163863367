/// 単位付きの数値を別の単位に変換する
///
/// @param {number (with unit)} $number
///   変換する値。
///   `px`, `rem`, `em`, `ex`, `%`, `vw`, `vh`, `vmin`, `vmax`,
///   `in`, `cm`, `mm`, `pt`, `pc`, `q` を受け入れる。
///
/// @param {string} $to-unit
///   変換後の単位。
///   `px`, `rem`, `em`, `ex`, `%`, `vw`, `vh`, `vmin`, `vmax`,
///   `in`, `cm`, `mm`, `pt`, `pc`, `q` を受け入れる。
///
/// @param {number (in px)} $reference-size-px [$base-font-size-px]
///   相対指定の単位を変換をする場合に基準となるサイズ。
///   `rem` はルートとなる `html` に指定した文字サイズ、`em`, `ex`, `%` 変換は基準となる親要素のサイズ、
///   `vw`, `vh`, `vmin`, `vmax` 変換は基準となるウィンドウサイズを指定する。
///
/// @return {number (with unit)}
///   単位が未指定の場合や、サポートしていない単位の場合、引数の値をそのまま返す。
///
/// @require {function} _in-array()
/// @require {function} _strip-unit()
/// @require {variable} $base-font-size-px
///
/// @example
///   @debug _convert-length(12px, em, 16px); // => 0.75em
///   @debug _convert-length(240px, vh, 460px); // => 52.17391vh

@function _convert-length(
  $number,
  $to-unit,
  $reference-size-px: $base-font-size-px
) {
  $from-unit: unit($number);

  @if $from-unit == $to-unit { @return $number; }

  @if $from-unit != 'px' {
    @if      $from-unit == 'rem'  { $number: $number * $base-font-size-px / 1rem; }
    @else if $from-unit == 'em'   { $number: $number * $reference-size-px / 1em;  }
    @else if $from-unit == 'ex'   { $number: $number * $reference-size-px / 2ex;  }
    @else if $from-unit == '%'    or
             $from-unit == 'vw'   or
             $from-unit == 'vh'   or
             $from-unit == 'vmin' or
             $from-unit == 'vmax' { $number: _strip-unit($number) * $reference-size-px / 100; }
    @else if $from-unit == 'in'   or
             $from-unit == 'cm'   or
             $from-unit == 'mm'   or
             $from-unit == 'pt'   or
             $from-unit == 'pc'   { $number: 0px + $number }
    @else if $from-unit == 'q'    { $number: 0px + (_strip-unit($number) * 0.25mm); }
    @else {
      @warn "The unit isn't supported; Returning original value.";
      @return $number;
    }
  }

  @if $to-unit != 'px' {
    @if      $to-unit == 'rem'  { @return $number * 1rem / $base-font-size-px; }
    @else if $to-unit == 'em'   { @return $number * 1em  / $reference-size-px; }
    @else if $to-unit == 'ex'   { @return $number * 2ex  / $reference-size-px; }
    @else if $to-unit == '%'    or
             $to-unit == 'vw'   or
             $to-unit == 'vh'   or
             $to-unit == 'vmin' or
             $to-unit == 'vmax' { @return _strip-unit($number * 100% / $reference-size-px) * 1#{$to-unit}; }
    @else if $to-unit == 'in'   { @return 0in + $number; }
    @else if $to-unit == 'cm'   { @return 0cm + $number; }
    @else if $to-unit == 'mm'   { @return 0mm + $number; }
    @else if $to-unit == 'pt'   { @return 0pt + $number; }
    @else if $to-unit == 'pc'   { @return 0pc + $number; }
    @else if $to-unit == 'q'    { @return _strip-unit(0mm + $number) * 4q; }
    @else {
      @warn "The unit isn't supported; Returning original value.";
      @return $number;
    }
  }
  @return $number;
}
