$article-list-bp: 'sm' !default;

/// 記事一覧レイアウト

.article-list {
  @include _unstyled(ul);

  &__item-inner { @include _row(null,null,nowrap); }
  &__media > img { width: 100%; height: auto; }
  &__content { @include _row-col(); }
  &__date { display: inline-block; }
  &__excerpt { text-align: justify; }

  // リスト型レイアウト
  &--line {
    @include _mq(#{$article-list-bp}, down){
      .article-list__item-inner { flex-wrap: wrap; }
      .article-list__content { @include _row-col(100%); }
    }
  }

  // マガジン型レイアウト
  &--magazine {}

  // カード型レイアウト
  &--card {
    @include _row();

    .article-list {
      &__item-inner { flex-direction: column; }
      &__media,
      &__content { @include _row-col(100%); }

      @include _mq(#{$article-list-bp}, down){
        &__item { @include _row-col(); }
      }
    }
  }
}
