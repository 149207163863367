/// くの字型矢印アイコンを生成する
///
/// @param {string} $direction
///   矢印の方向
///   `up`, `right`, `down`, `left`,
///   `up-right`, `down-right`, `down-left`, `up-left` を受け入れる
///
/// @param {number (in px)} $size
///   矢印のサイズ。1辺の長さとしてセットされる
///
/// @param {number (in px)} $weight [1px]
///   線の太さ
///
/// @param {color} $color [#000]
///   矢印の色
///
/// @example scss
///   .element {
///     @include _arrow(right,10px);
///   }
///
///   // CSS Output
///   .element {
///     display: inline-block;
///     width: 10px;
///     height: 10px;
///     border-top: 1px solid #000;
///     border-right: 1px solid #000;
///     transform: rotate(45deg);
///   }

@mixin _arrow(
  $direction,
  $size,
  $weight: 1px,
  $color: #000
) {
  display: inline-block;
  width: $size;
  height: $size;
  border-top: $weight solid $color;
  border-right: $weight solid $color;

  @if      $direction == 'up' { transform: rotate(-45deg); }
  @else if $direction == 'right' { transform: rotate(45deg); }
  @else if $direction == 'bottom' { transform: rotate(135deg); }
  @else if $direction == 'left' { transform: rotate(-135deg); }
  @else if $direction == 'up-right' {}
  @else if $direction == 'down-right' { transform: rotate(90deg); }
  @else if $direction == 'down-left' { transform: rotate(180deg); }
  @else if $direction == 'up-left' { transform: rotate(-90deg); }
}
