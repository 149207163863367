*,*:before,*:after { box-sizing: border-box }

// 画面方向変更時において、文字サイズが自動調整されるのを防ぐ
html { -webkit-text-size-adjust: 100%; }

// シャドウが掛かった文字のハイライト時における視認性低下を防ぐ
// `text-shadow:none` だけの指定では背景色が変化しないため、併せて `background` を指定する
// ::selection {
//   background: Highlight;
//   color: #fff;
//   text-shadow:none;
// }

// キーボード操作でアクセスできない要素について、
// 不要なフォーカスアウトラインが表示されるのを抑制
[tabindex="-1"]:focus { outline: 0 !important; }

// 全てのブラウザで `margin:8px` が設定されているが不要なため無効化
body { margin: 0; }

// モダンブラウザにおいて、`h1` 要素を `article`, `aside`, `nav`, `section` 内に置いた場合に
// `margin` と `font-size` が変更されるのを修正
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

// IE, Edge で指定されている `overflow:hidden` を修正
hr { overflow: visible; }

// 全てのブラウザでフォントサイズを継承するよう修正
// pre, code, kbd, samp { font-family: monospace, monospace; }

// IE10 において、リンククリック時に背景色が灰色に変わるのを防ぐ
a { background-color: transparent; }

// Chrome, Safari で指定されている `font-weight:bold` を修正
b, strong { font-weight: bolder; }

// 上付き・下付き文字の使用時に行の高さが変わるのを防ぐ
sub, sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }

// IE10- でリンク画像に枠線が表示されるのを防ぐ
img { border-style:none; }

// 画像等の要素について、底面に生じる隙間（ディセンダ空間）を取り除く
audio, canvas, iframe, img, svg, video { vertical-align: middle; }

// IE において、SVG画像が描画領域からはみ出すのを防ぐ
svg { overflow: hidden; }

// IE に HTML5 新要素を対応させる
progress { display: block; } //IE10-
details, main, summary { display: block; } //IE

// 1. 全ブラウザでフォントの種類・サイズを修正
// 2. IE, Edge で設定されている `overflow:hidden` を修正
// 3. Firefox, IE での `text-transform` の継承を無効化
// 4. Firefoxにおいて、フォーカス時にボタン内部に点線が表示されるのを防ぎ、
//    ボタン枠に合わせて点線が表示されるよう修正
button,
[type="button"],
[type="reset"],
[type="submit"] {
  font-size: 100%; //1
  font-family: inherit; //1
  overflow: visible; //2
  text-transform: none; //3

  &::-moz-focus-inner {
    border: none; //4
    padding: 0; //4
  }
  &:-moz-focusring {
    outline: 1px dotted ButtonText; //4
  }
}

// 1. Firefoxにおける`margin:1px 0`を修正
// 2. 全ブラウザでフォントの種類・サイズを修正
// 4. IE10既定の垂直方向のスクロールバーを削除
textarea {
  margin: 0;
  font-family: inherit; //2
  font-size: 100%; //2
  vertical-align: top;
  overflow: auto; //4
  resize: vertical;
}

input {
  margin: 0;
  font-size: 100%; //1
  font-family: inherit; //1
  overflow: visible; //2
}
