/// @font-face宣言を生成する
/// ブラウザの現状を鑑みて `woff`, `ttf` のみサポート
///
/// @param {string} $name
///   フォント名
///
/// @param {string} $path
///   フォントファイルまでのパス（拡張子は含めない）
///
/// @param {string} $weight [null]
///   フォントのウェイト
///
/// @param {string} $style [null]
///   フォントのスタイル
///
/// @param {list} $extension [woff ttf]
///   フォントの形式
///
/// @example scss
///   @include _font-face('ExampleFont', '../fonts/example-regular', 400, null, ttf);
///
///   // CSS Output
///   @font-face {
///     font-family: "ExampleFont";
///     font-weight: 400;
///     src: url("../fonts/example-regular.ttf") format("truetype");
///   }

@mixin _font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $extension: woff ttf
) {
  $src: null;
  $formats:(ttf: "truetype", woff: "woff");

  @each $i in $extension {
    @if map-has-key($formats, $i) {
      $format: map-get($formats, $i);
      $src: append($src, url(quote($path + "." + $i)) format(quote($format)), comma);
    } @else {
      @warn "#{$i} is not supported.";
    }
  }

  @font-face {
    font-family: quote($name);
    font-weight: $weight;
    font-style: $style;
    src: $src;
  }
}





